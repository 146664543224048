"use client"

import {
  forwardRef,
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type CSSProperties,
  type MouseEvent,
  type ReactNode,
  type Ref,
} from "react"
import dynamic from "next/dynamic"
import { default as NextLink, type LinkProps as NextLinkProps } from "next/link"
import { usePathname, useRouter } from "next/navigation"
import clsx from "clsx"

import { useIsHover } from "@unlikelystudio/react-hooks"

import useMarket from "~/hooks/useMarket"

const BabackLink = dynamic(() => import("~/components/ui/BabackCta").then((module) => module.BabackLink))
export interface AbstractLinkProps extends Omit<NextLinkProps, "href"> {
  className?: string
  activeClassName?: string
  isExternal?: boolean
  href?: NextLinkProps["href"]
  onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>
  rel?: string
  children?: ReactNode | (({ isHover, isActive }: { isActive: boolean; isHover: boolean }) => JSX.Element)
  target?: string
  isLoading?: boolean
  disabled?: boolean
  obfuscate?: boolean
  style?: CSSProperties
  htmlType?: "submit"
}

function LinkEntity(
  {
    className,
    activeClassName,
    href,
    isExternal,
    obfuscate,
    disabled,
    children,
    onMouseEnter,
    onMouseLeave,
    isLoading,
    htmlType,
    // @ts-ignore
    iconFromSb: _iconFromSb, // it appears this prop is spread from somewhere and creates a dom validation error
    prefetch: _prefetch,
    legacyBehavior: _legacyBehavior,
    ...rest
  }: AbstractLinkProps,
  ref: Ref<HTMLButtonElement | HTMLAnchorElement>
): JSX.Element | null {
  const router = useRouter()
  const pathname = usePathname()
  const isActive = !isExternal && href === pathname
  const market = useMarket()

  const [isHover, mouseEvents] = useIsHover({
    onMouseEnter,
    onMouseLeave,
    disabled,
  })

  const Children = <>{!isLoading && (typeof children === "function" ? children({ isHover, isActive }) : children)}</>

  if (obfuscate) {
    const onClick = (e: MouseEvent<HTMLElement>) => {
      rest?.onClick?.(e as MouseEvent<HTMLAnchorElement>)

      if (rest.target === "blank") {
        href && window.open(href as string, "_blank")
      } else {
        href && router.push(href as string)
      }
    }

    return (
      <button
        data-comp={"Abstracts/Link"}
        ref={ref as Ref<HTMLButtonElement>}
        className={clsx(className, {
          ...(activeClassName && {
            [activeClassName]: activeClassName && isActive,
          }),
        })}
        {...(rest as Omit<ButtonHTMLAttributes<HTMLButtonElement>, "href">)}
        onClick={onClick}
        {...mouseEvents}
      >
        {Children}
      </button>
    )
  }

  if (!href) {
    return (
      <button
        data-comp={"Abstracts/Link"}
        ref={ref as Ref<HTMLButtonElement>}
        className={clsx(className, {
          ...(activeClassName && {
            [activeClassName]: activeClassName && isActive,
          }),
        })}
        {...(rest as Omit<ButtonHTMLAttributes<HTMLButtonElement>, "href">)}
        {...mouseEvents}
        {...(htmlType && { type: htmlType })}
      >
        <>{Children}</>
      </button>
    )
  }

  if (typeof href === "string" && href === "/baback") {
    if (market !== "France") return null

    return (
      <BabackLink
        data-comp={"Abstracts/Link"}
        ref={ref as Ref<HTMLButtonElement>}
        className={clsx(className, {
          ...(activeClassName && {
            [activeClassName]: activeClassName && isActive,
          }),
        })}
        {...(rest as Omit<ButtonHTMLAttributes<HTMLButtonElement>, "href">)}
        {...mouseEvents}
      >
        {Children}
      </BabackLink>
    )
  }

  if (isExternal) {
    return (
      <a
        data-comp={"Abstracts/Link"}
        ref={ref as Ref<HTMLAnchorElement>}
        className={className}
        rel="noreferrer noopener"
        href={href.toString()}
        draggable={false}
        {...(rest as Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">)}
        {...mouseEvents}
      >
        {Children}
      </a>
    )
  }

  return (
    <NextLink
      data-comp={"Abstracts/Link"}
      href={href}
      ref={ref as Ref<HTMLAnchorElement>}
      className={clsx(className, {
        ...(activeClassName && {
          [activeClassName]: activeClassName && isActive,
        }),
      })}
      draggable={false}
      {...rest}
      {...mouseEvents}
    >
      {Children}
    </NextLink>
  )
}

export const AbstractLink = forwardRef(LinkEntity)
